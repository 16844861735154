import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import './HeroHeader1_an81MjmJ.css';

const HeroHeader1_an81MjmJ = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);

  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Meta Segment Anything Model 2 (SAM 2)</title>
        <meta name="description" content="SAM 2 is a segmentation model that enables fast, precise selection of any object in any video or image." />
        <meta name="keywords" content="Meta, SAM 2, segmentation model, AI, machine learning" />
      </Helmet>
      <header className="ezy__header1_an81MjmJ">
        <div className="container">
          <div className="grid">
            <div className="col-span-12 lg:col-span-6 xl:pr-12 text-center lg:text-start">
              <div className="flex flex-col justify-center h-full">
                <h2>Meta Segment Anything Model 2 (SAM 2)</h2>
                <p>
                  SAM 2 is a segmentation model that enables fast, precise selection of any object in any video or image.
                </p>
                <div className="button-container">
                  <a href="https://ai.meta.com/SAM2/#our-approach:~:text=video%20or%20image.-,Try%20the%20demo,-Download%20the%20model" 
                    className="button button-primary" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    aria-label="Try the demo of SAM 2">
                    Try the demo
                  </a>
                  <a href="https://github.com/facebookresearch/segment-anything-2" 
                    className="button button-secondary"
                    target="_blank" 
                    rel="noopener noreferrer"
                    aria-label="Download the SAM 2 model">
                    Download the model
                  </a>
                </div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6 mt-12 lg:mt-0 relative">
              <video
                ref={videoRef}
                src="/video/An9qEadt_dOrer9g2pvdBjFb8U-2SdrwEhy2kAjfsD12fT8fcb_w7wVU70ObZCheuXznT8DYuL6E9LaL8nNLv7jK.mp4"
                autoPlay
                loop
                muted
                className="w-full h-full object-cover rounded-lg shadow-lg"
                aria-label="Demo video of SAM 2 model"
              />
              <button 
                onClick={togglePlayPause} 
                className="video-control-button"
                aria-label={isPlaying ? "Pause video" : "Play video"}
              >
                {isPlaying ? (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path d="M5 3h4v18H5V3zm10 0h4v18h-4V3z" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10">
                    <path d="M8 5v14l11-7z" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default HeroHeader1_an81MjmJ;
