import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import "./Careers5_7mbzXlZn.css";

const resources = [
  {
    title: "Read the AI at Meta blog",
    link: "https://ai.meta.com/blog/segment-anything-2-video/"
  },
  {
    title: "Read the research paper",
    link: "https://ai.meta.com/research/publications/sam-2-segment-anything-in-images-and-videos/"
  },
  {
    title: "Download the dataset",
    link: "https://ai.meta.com/datasets/segment-anything-video-downloads/"
  },
  {
    title: "Explore the dataset",
    link: "https://ai.meta.com/datasets/segment-anything-video/"
  },
  {
    title: "Download the model",
    link: "https://github.com/facebookresearch/segment-anything-2"
  },
  {
    title: "Try the demo",
    link: "https://sam2.metademolab.com/"
  }
];

const ResourceCard = ({ title, link }) => (
  <a href={link} target="_blank" rel="noopener noreferrer" className="resource-card">
    <div className="resource-content">
      <span className="resource-title">{title}</span>
      <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="resource-icon" />
    </div>
  </a>
);

const Careers5_7mbzXlZn = () => {
  return (
    <section id="resources" className="ezy__careers5_7mbzXlZn">
      <div className="container">
        <h1>Explore additional resources</h1>
        <div className="resources-grid">
          {resources.map((resource, index) => (
            <ResourceCard key={index} title={resource.title} link={resource.link} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Careers5_7mbzXlZn;