import React from "react";
import "./EPOthers5_BLHjRLLs.css";

const EPOthers5_BLHjRLLs = () => {
  return (
    <section className="epothers5-container">
      <div className="container px-4 mx-auto">
        <div className="epothers5-header">
          <h1>Potential model applications</h1>
          <p>
            SAM 2 can be used by itself, or as part of a larger system with other models in future work to enable novel experiences.
          </p>
          <a 
            href="https://github.com/facebookresearch/segment-anything-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download the model
          </a>
        </div>
        <div className="epothers5-content">
          <div className="epothers5-item">
            <video
              src="video/An9tkZyPbnv1lM4X49aqb9UZSla7n9jp1eprHCJuBwud7vrlfBhEOHqtSRSa9s9N_qKld4J2wUAxFzkr4cqL6vBe.mp4"
              autoPlay
              loop
              muted
            />
            <h3>Extensible outputs</h3>
            <p>
              The video object segmentation outputs from SAM 2 could be used as input to other AI systems such as modern video generation models to enable precise editing capabilities.
            </p>
          </div>
          <div className="epothers5-item">
            <video
              src="video/An_Etqzk2xnJy1jVEAzEsj-9Hm92hZ7x2rJWHi1kr9LRaLPly9xBJqye5Nh6Bf4Agk_NZ6l4_rQ0YfGGCubAfCsi.mp4"
              autoPlay
              loop
              muted
            />
            <h3>Extensible inputs</h3>
            <p>
              SAM 2 can be extended to take other types of input prompts such as in the future enabling creative ways of interacting with objects in real-time or live video.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EPOthers5_BLHjRLLs;
