import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./Feature28_PFFAALyd.css";

const Feature28_PFFAALyd = () => {
  return (
    <section className="py-14 md:py-24 bg-white text-zinc-900 relative overflow-hidden" id="approach">
      <div className="rounded-tl-[40px] rounded-bl-[40px] absolute left-[55%] bottom-0 top-0 w-[50vw] bg-blue-50 hidden lg:block"></div>
      <div className="container px-4 mx-auto relative">
        <div className="flex flex-col md:flex-row gap-6 md:gap-12 items-center justify-between">
          <div className="w-full md:w-1/2 xl:pr-12 text-center lg:text-start my-12">
            <h2 className="text-2xl leading-none md:text-[45px] font-bold mb-6">
              The next generation of Meta Segment Anything
            </h2>
            <p className="text-lg md:text-[22px] leading-normal opacity-80">
              SAM 2 brings state-of-the-art video and image segmentation capabilities into a single model, while preserving a simple design and fast inference speed.
            </p>
            <div className="mt-12">
              <a
                href="https://ai.meta.com/research/publications/sam-2-segment-anything-in-images-and-videos/"
                target="_blank"
                rel="noopener noreferrer"
                className="custom-button"
              >
                <FontAwesomeIcon icon={faArrowRight} className="mr-2" />
                Read The Paper
              </a>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <video
              src="video/An8M6Smp6fr8qp4zGNW1h53QQgkUklBVoy7zyZnCq2JbtnNuwRgRfHW_DFlw5wILkfkda6YrOhqVgqETroWa7Nsg.mp4"
              autoPlay
              loop
              muted
              className="mx-auto"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature28_PFFAALyd;