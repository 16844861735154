import React from "react";
import "./HeroHeader25_HFoaQXeyi.css";

const HeroHeader25_HFoaQXeyi = () => {
  return (
    <div className="hero-header-25">
      <div className="content">
        <div className="content-inner">
          <h2 className="subtitle">Access our research</h2>
          <h1 className="title">Open innovation</h1>
          <p className="description">
            To enable the research community to build upon this work,
            we're publicly releasing a pretrained Segment Anything 2
            model, along with the SA-V dataset, a demo, and code.
          </p>
          <button className="download-button">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 3a1 1 0 00-1 1v5H4a1 1 0 100 2h5v5a1 1 0 102 0v-5h5a1 1 0 100-2h-5V4a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
            Download the model
          </button>
        </div>
        <div className="highlights">
          <h3>Highlights</h3>
          <ul>
            <li>We are providing transparency into the SAM 2 training data</li>
            <li>We prioritized geographic diversity in the SA-V dataset for real-world representation</li>
            <li>We conducted a fairness evaluation of SAM 2</li>
          </ul>
        </div>
      </div>
      <div className="image-container">
        <div className="image-wrapper">
          <img src="image/452743728_468159639303838_8938222760409751654_n.jpg" alt="Coding on laptop" />
        </div>
      </div>
    </div>
  );
};

export default HeroHeader25_HFoaQXeyi;