import React from "react";
import "./Feature44_PRrrKQj7.css";

const features = [
  {
    desc: "SAM 2 improves on SAM for segmentation in images"
  },
  {
    desc: "SAM 2 outperforms existing video object segmentation models, especially for tracking parts"
  },
  {
    desc: "SAM 2 requires less interaction time than existing interactive video segmentation methods"
  }
];

const FeaturedItem = ({ feature }) => {
  return (
    <div className="feature-item">
      <div className="feature-bullet"></div>
      <p className="feature-text">{feature.desc}</p>
    </div>
  );
};

const Feature44_PRrrKQj7 = () => {
  return (
    <section className="py-14 md:py-24 bg-white text-zinc-900 relative">
      <div className="container">
        <div className="grid grid-cols-12 gap-8">
          <div className="col-span-12 lg:col-span-6 order-2 lg:order-1">
            <video
              src="video/An8h31y1S6up2s5Ee5lTwjVRdhGxusG05Y64AuCn6NrGMBmEPqQ4PaTGW753_eJd63FCao6C88jqCiXbOjEqwGhS.mp4"
              autoPlay
              loop
              muted
              className="feature-image"
            />
          </div>
          <div className="col-span-12 lg:col-span-6 order-1 lg:order-2 feature-content">
            <h1>State-of-the-art performance for object segmentation</h1>
            <p>
              SAM 2 outperforms the best models in the field for object segmentation in videos and images.
            </p>
            <div className="highlights">
              <h2>Highlights</h2>
              {features.map((feature, i) => (
                <FeaturedItem feature={feature} key={i} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature44_PRrrKQj7;
