import React from 'react';
import './Blog7_5rzfer1f.css';

const blogs = [
  {
    title: "Select objects and make adjustments across video frames",
    description: "Using SAM 2, you can select one or multiple objects in a video frame. Use additional prompts to refine the model predictions.",
    video: "video/An8Jho5818pRqJVhN2S2pLO4uq4AePXFh-n7uw302XsMhnq3qjac3W3DbqJlVVs5Eijkve7lX-vS_qcZ4gip0Qzv.mp4",
  },
  {
    title: "Robust segmentation, even in unfamiliar videos",
    description: "SAM 2 is capable of strong zero-shot performance for objects, images and videos not previously seen during model training, enabling use in a wide range of real-world applications.",
    video: "video/An9sRL4Zbpn9gWGWzrcKK8h4V3L_hpkHpEVT76Fftjc03wffHIWHeL6TB5NNCch91unKkLmYfeA4pfy1hSf15FkW (1).mp4",
  },
  {
    title: "Real-time interactivity and results",
    description: "SAM 2 is designed for efficient video processing with streaming inference to enable real-time, interactive applications.",
    video: "video/An9nkNG8q8XEuSinYe2WYBTodA-LxUHwOkdZRXCb0fsrCuF3FyVyeZIZqRbKT1vTTCPHsXRUmxcnljlGr7S2_L4.mp4",
  },
];

const BlogItem = ({ blog }) => (
  <article className="blog-item">
    <div className="blog-video">
      <video src={blog.video} autoPlay loop muted playsInline />
    </div>
    <div className="blog-content">
      <h3>{blog.title}</h3>
      <p>{blog.description}</p>
    </div>
  </article>
);

const Blog7_5rzfer1f = () => {
  return (
    <section id="capabilities" className="blog-section">
      <div className="container">
        <div className="blog-header">
          <h2>Segment any object, now in any video or image.</h2>
          <p>
            SAM 2 is the first unified model for segmenting objects across images and videos. 
            You can use a click, box, or mask as the input to select an object on any image or frame of video.
          </p>
          <a href="https://ai.meta.com/research/publications/sam-2-segment-anything-in-images-and-videos/" 
          className="read-paper-button"
          target="_blank">
            Read the research paper→
          </a>
        </div>
        <div className="blog-grid">
          {blogs.map((blog, i) => (
            <BlogItem key={i} blog={blog} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blog7_5rzfer1f;