import React from "react";
import "./Faq3_RUA0hX3m.css";

const Faq3_RUA0hX3m = () => {
  return (
    <section className="sam2-faq-section">
      <div className="sam2-container">
        <h1 className="sam2-title">Model architecture</h1>
        <h2 className="sam2-subtitle">Meta Segment Anything Model 2 design</h2>
        <div className="sam2-content">
          <p>
            The SAM 2 model extends the promptable capability of SAM to the video domain by adding a
            per session memory module that captures information about the target object in the video.
            This allows SAM 2 to track the selected object throughout all video frames, even if the object
            temporarily disappears from view, as the model has context of the object from previous
            frames. SAM 2 also supports the ability to make corrections in the mask prediction based on
            additional prompts on any frame.
          </p>
          <p>
            SAM 2's streaming architecture—which processes video frames one at a time—is also a
            natural generalization of SAM to the video domain. When SAM 2 is applied to images, the
            memory module is empty and the model behaves like SAM.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Faq3_RUA0hX3m;