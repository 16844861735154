import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCannabis } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import "./Service22_kneK7unh.css";

const ServiceItem = ({ service }) => (
  <div className="bg-white dark:bg-slate-800 shadow-xl rounded-xl h-full">
    <div className="p-6 md:p-12">
      <div className="w-[75px] h-[75px] rounded-full text-[26px] shadow-xl flex justify-center items-center mb-6">
        <FontAwesomeIcon icon={service.icon} />
      </div>
      <h4 className="text-2xl mb-6 font-bold">{service.title}</h4>
      <p className="opacity-70 leading-[1.8]">{service.description}</p>
    </div>
  </div>
);

ServiceItem.propTypes = {
  service: PropTypes.object.isRequired,
};

const Service22_kneK7unh = () => {
  return (
    <section className="ezy__service22_kneK7unh py-14 md:py-24 bg-gray-100 text-gray-800 relative z-[1]">
      <div className="container px-4 mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-4xl md:text-5xl font-bold mb-4">
            Try it yourself
          </h2>
          <p className="text-lg opacity-80 max-w-2xl mx-auto mb-8">
            Track an object across any video interactively with as little as a 
            single click on one frame, and create fun effects.
          </p>
          <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg transition duration-300 inline-flex items-center">
            <svg className="w-4 h-4 mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
            Try the demo
          </button>
        </div>
        <div className="mt-12">
          <div className="grid grid-cols-6 gap-6 max-w-7xl mx-auto">
            <div className="col-span-6">
              <video 
                src="video/An_vyMkP7sMt_Aur-u3NQQ9SCcdWqiNMC8Sl-YclekAJfEKDMpttjeD2TsT_3lJkAPeGTWf1TML2bA7Xkx9ANPXy.mp4" 
                autoPlay 
                loop 
                muted 
                className="w-full h-auto rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service22_kneK7unh;
