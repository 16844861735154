import React from "react";
import PropTypes from "prop-types";
import "./Navigation1_aM92P5O2.css";

const routes = [
  { name: "Capabilities", href: "#capabilities", isActive: false },
  { name: "Our approach", href: "#approach", isActive: false },
  { name: "Resources", href: "#resources", isActive: false },
];

const NavMenu = ({ routes }) => (
  <ul className="nav-menu">
    {routes.map((route, i) => (
      <li key={i}>
        <a
          className={route.isActive ? "active" : ""}
          href={route.href}
        >
          {route.name}
        </a>
      </li>
    ))}
  </ul>
);

NavMenu.propTypes = {
  routes: PropTypes.array.isRequired,
};

const Navigation1_aM92P5O2 = () => {
  return (
    <div className="ezy__nav1_aM92P5O2">
      <nav className="container">
        <div className="flex justify-between items-center">
          <a className="logo" href="#!">
            Segment Anything
          </a>
          <div className="flex items-center">
            <NavMenu routes={routes} />
            <div className="divider"></div>
            <a
              href="https://sam2.metademolab.com/"
              className="try-demo" target="_blank" rel="noopener noreferrer"
            >
              Try demo
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navigation1_aM92P5O2;