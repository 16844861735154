import React from 'react';
import { Helmet } from 'react-helmet';
import Navigation1_aM92P5O2 from './components/Navigation1_aM92P5O2';
import HeroHeader1_an81MjmJ from './components/HeroHeader1_an81MjmJ';
import Blog7_5rzfer1f from './components/Blog7_5rzfer1f';
import Feature44_PRrrKQj7 from './components/Feature44_PRrrKQj7';
import Service22_kneK7unh from './components/Service22_kneK7unh';
import Feature28_PFFAALyd from './components/Feature28_PFFAALyd';
import Faq3_RUA0hX3m from './components/Faq3_RUA0hX3m';
import HeroHeader25_wBM89yVBj from './components/HeroHeader25_wBM89yVBj';
import HeroHeader25_HFoaQXeyi from './components/HeroHeader25_HFoaQXeyi';
import EPOthers5_BLHjRLLs from './components/EPOthers5_BLHjRLLs';
import Careers5_7mbzXlZn from './components/Careers5_7mbzXlZn';
import './App.css';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Meta's Segment Anything 2 Model</title>
        <meta name="description" content="Discover Meta's advanced Segment Anything 2 model for efficient segmentation tasks." />
        <meta name="keywords" content="Meta, Segment Anything 2, segmentation model, AI, machine learning" />
      </Helmet>
      <Navigation1_aM92P5O2 />
      <div className="content">
        <HeroHeader1_an81MjmJ />
        <Blog7_5rzfer1f />
        <Feature44_PRrrKQj7 />
        <Service22_kneK7unh />
        <Feature28_PFFAALyd />
        <Faq3_RUA0hX3m />
        <HeroHeader25_wBM89yVBj />
        <HeroHeader25_HFoaQXeyi />
        <EPOthers5_BLHjRLLs />
        <Careers5_7mbzXlZn />
      </div>
    </div>
  );
}

export default App;
