import React from "react";
import "./HeroHeader25_wBM89yVBj.css";

const HeroHeader25_wBM89yVBj = () => {
  return (
    <div className="hero-header-25">
      <div className="video-container">
        <div className="video-wrapper">
          <video
            src="video/An9rqhh8jLAD81wdPLApEcu-1Z2NzXx4_528PrvelbMmWVgTvtk8DgdwDHRYohgxxotkX_WENwyB-eegqi3EQXlQ.mp4"
            autoPlay
            loop
            muted
            playsInline
          />
        </div>
      </div>
      <div className="content">
        <div className="content-inner">
          <h2 className="subtitle">The Segment Anything Video Dataset</h2>
          <h1 className="title">A large and diverse video segmentation dataset</h1>
          <p className="description">
            SAM 2 was trained on a large and diverse set of videos and masklets (object masks over time), created by applying SAM 2 interactively in a model in the loop data-engine. The training data includes the SA-V dataset, which we are open sourcing.
          </p>
          <p className="description">
            Please email <a href="mailto:support@segment-anything.com" className="email" target="_blank">support@segment-anything.com</a> with any issues or questions regarding the SA-V dataset.
          </p>
          <a href="https://ai.meta.com/datasets/segment-anything-video/"
              className="explore-button"
              target="_blank">
            Explore the dataset
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M5 12h14M12 5l7 7-7 7"/>
            </svg>
          </a>
        </div>
        <div className="highlights">
          <h3>Highlights</h3>
          <ul>
            <li>~600K+ masklets collected on ~51K videos</li>
            <li>Geographically diverse, real world scenarios collected across 47 countries</li>
            <li>Annotations include whole objects, parts, and challenging occlusions</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HeroHeader25_wBM89yVBj;